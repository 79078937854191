import {IProjectDetail} from '../../pages/graph/IProjectDetail';
import {
  EBoqClassification,
  EInfrastructureClassification,
  EPackageClassification,
  EPackageOptionStatus,
  getInfrastructureClassification
} from './classificationEnum';
import {IInfrastructure} from '../../Models/IInfrastructure';

export type NullableNumber = number | null;

export interface IProjectSummaryReport {
  base: NullableNumber;
  baseVerified: NullableNumber;
  budget_amt: NullableNumber;
  option: NullableNumber;
  praxisValue: NullableNumber;
  project_name: string;
}

export interface IRefBoq {
  boq_header_num: NullableNumber;
  boqCd: string;
  boqClassificationInd: EBoqClassification | null;
  boqDesc: string;
  boqModelId?: unknown;
  boqName: string;
  boqNum: number;
  createdDt?: string;
  infrastructureNum: NullableNumber;
  milestone?: string;
  milestoneNum: NullableNumber;
  modifiedBy?: string;
  modifiedDt?: string;
  parentBoqNum: NullableNumber;
  projectStageInd?: NullableNumber;
  totalCost?: number;
  weekNum?: number;
}

export interface IRefMilestone {
  milestoneName: string;
  milestoneNum: number;
}

export interface ICostOverTime {
  cost: number;
  weekNum: number;
}

export interface IInfrastructureCostOverWeek {
  [infrastructureNum: number]: ICostOverTime[];
}

export class CostOverTime {
  constructor(private projectSnapshots: ICostOverTime[]) {
  }

  public get latestWeek(): number {
    let maxWeek = 1;
    this.projectCostOverTime.forEach(v => {
      if (v.weekNum > maxWeek) {
        maxWeek = v.weekNum;
      }
    });
    return maxWeek;
  }

  public get projectCostOverTime(): ICostOverTime[] {
    return this.projectSnapshots;
  }

}

export interface IClassificationCost {
  classificationId: number;
  effectiveDate: string;
  cost: number;
}

export interface IInfraProjectBoq extends IProjectDetail,
  Pick<IInfrastructure,
    'packageNum' | 'infrastructureName' | 'infrastructureClassificationInd' | 'infrastructurePreferredInd' | 'infrastructureNum'> {
  classificationCost: IClassificationCosts;
  refMilestones: IRefMilestone[];
  refBoqs: IRefBoq[];
  packageName: string;
  projectStageInd: number;
  packageClassificationInd?: number; //0 is normal package; 1 is option package
  packageOptionStatusInd?: number;
}

export interface IClassificationCosts {
  [p: string]: IClassificationCost;
}

export interface IBoqClassification {
  boqClassificationId: string;
  boqClassificationName: string;
}

export class InfrastructureWithAllBoqs implements IInfraProjectBoq {
  classificationCost: IClassificationCosts;
  clientName: string;
  clientNum: number;
  infrastructureClassificationInd: number;
  infrastructureName: string;
  infrastructurePreferredInd: number;
  packageClassificationInd: number;
  packageName: string;
  packageNum: number;
  projectName: string;
  projectNum: number;
  projectStageInd: number;
  refBoqs: IRefBoq[];
  refMilestones: IRefMilestone[];
  packageOptionStatusInd?: number;
  infrastructureNum: number;

  constructor(value: IInfraProjectBoq) {
    this.infrastructureNum = value.infrastructureNum;
    this.classificationCost = value.classificationCost;
    this.clientName = value.clientName;
    this.clientNum = value.clientNum;
    this.infrastructureClassificationInd = value.infrastructureClassificationInd;
    this.infrastructureName = value.infrastructureName;
    this.infrastructurePreferredInd = value.infrastructurePreferredInd;
    this.packageClassificationInd = value.packageClassificationInd;
    this.packageName = value.packageName;
    this.packageNum = value.packageNum;
    this.projectName = value.projectName;
    this.projectNum = value.projectNum;
    this.projectStageInd = value.projectStageInd;
    this.refBoqs = value.refBoqs;
    this.refMilestones = value.refMilestones;
    this.packageOptionStatusInd = value.packageOptionStatusInd;
    this.postProcess();
  }

  private postProcess() {
    for (const item of this.refBoqs) {
      if (item.milestoneNum) {
        item.milestone = this.refMilestones.filter(x => x.milestoneNum === item.milestoneNum)[0].milestoneName;
      }
    }
  }

  public get infrastructureClassification(): EInfrastructureClassification {
    return getInfrastructureClassification(this.infrastructureClassificationInd);
  }

  public get packageClassification(): EPackageClassification {
    switch (this.packageClassificationInd) {
      case 0:
      case null:
      case undefined:
        return EPackageClassification.Base;
      case 1:
        return EPackageClassification.Option;
      default:
        throw new Error(`Invalid packageClassificationInd: ${this.packageClassificationInd}`);
    }
  }

  public get isPreferred(): boolean {
    switch (this.infrastructurePreferredInd) {
      case 0:
      case null:
      case undefined:
        return false;
      case 1:
        return true;
      default:
        throw new Error(`Invalid infrastructurePreferredInd: ${this.infrastructurePreferredInd}`);
    }
  }

  public get packageOptionStatus(): EPackageOptionStatus {
    switch (this.packageOptionStatusInd) {
      case 0:
        return EPackageOptionStatus.Review;
      case 1:
        return EPackageOptionStatus.Accepted;
      case 2:
        return EPackageOptionStatus.Declined;
      case 3:
      case null:
      case undefined:
        return EPackageOptionStatus.NotApplicable;
      default:
        throw new Error(`Invalid packageOptionStatusInd: ${this.packageOptionStatusInd}`);
    }
  }

  public get boqClassification(): EBoqClassification {
    switch (this.infrastructureClassification) {
      case EInfrastructureClassification.Base:
        return EBoqClassification.Base;
      case EInfrastructureClassification.Option:
        return EBoqClassification.Option;
      case EInfrastructureClassification.BaseOption:
        return EBoqClassification.BaseOption;
      default:
        throw new Error(`[get boqClassification()] Invalid infrastructureClassification: ${this.infrastructureClassification}`);
    }
  }

  public get possibleBoqClassifications(): IBoqClassification[] {
    const result = [
      {boqClassificationId: '', boqClassificationName: 'Choose'},
    ];
    switch (this.infrastructureClassification) {
      case EInfrastructureClassification.Base:
        result.push(
          {boqClassificationId: '1', boqClassificationName: 'Base'},
          {boqClassificationId: '2', boqClassificationName: 'Base Verified'},
        );
        break;
      case EInfrastructureClassification.Option:
        result.push({boqClassificationId: '4', boqClassificationName: 'Option'});
        break;
      case EInfrastructureClassification.BaseOption:
        result.push({boqClassificationId: '5', boqClassificationName: 'Base Option'});
        break;
      default:
        throw new Error(`[get possibleBoqClassifications] Invalid infrastructureClassification: ${this.infrastructureClassification}`);
    }
    return result;
  }
}
