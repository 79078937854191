import { ProjectDetails } from '../../Models/Projectdetails';
import { AdminService } from 'src/app/admin/admin-service/admin.service';
import { Component, ElementRef, Inject, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Helpers } from 'src/app/helpers/Helpers';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertBoxComponent } from '../alertbox/alert-box.component';
import { DateAdapter } from '@angular/material/core';
import { cons } from 'fp-ts/lib/ReadonlyArray';

@Component({
  selector: 'app-addproject',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddProjectComponent implements OnInit {
  clicked = false;
  isSubmitted = false;
  id: any;
  show = false;
  titleName: string;
  projectForm: FormGroup;
  projectDetails: ProjectDetails;
  boqTemplates: Array<{ name: string; value: string }>;
  boqTemplateFiles: Array<File>;
  errorMessage?: string;

  constructor(
    private dialogref: MatDialogRef<AddProjectComponent>,
    private dialog: MatDialog,
    private authi: AdminService,
    private spinner: NgxSpinnerService,
    private help: Helpers,
    private formbuilder: FormBuilder,
    private elRef: ElementRef,
    private dateAdapter: DateAdapter<Date>,
    @Inject(MAT_DIALOG_DATA) public dataid: any,
  ) {
    this.dateAdapter.setLocale('en-au');

    this.boqTemplates = [{
      name: 'Choose', value: ''
    }, {
      name: 'RMS Standard BOQ', value: '1'
    }, {
      name: 'Upload a file', value: 'upload-file-option'
    }];
    this.boqTemplateFiles = [];
  }

  ngOnInit() {

    this.projectDetails = {};
    this.projectForm = this.formbuilder.group({
      projectName: ['', Validators.required],
      projectDesc: ['', Validators.required],
      templateBoqNum: ['1', Validators.required],
      projectStageInd: ['', Validators.required],
      templateFile: [null],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required]
    },
      {
        validator: Validators.compose([
          this.dateLessThanValidator('startDate', 'endDate', { 'startDate': true })
        ])
      }
    );

    if (this.dataid) {
      this.show = true;
      this.titleName = 'Edit Project ';
      this.getProjectdata(this.dataid.id);
      this.removeTemplateBoqNumValidator();
    } else {
      this.show = false;
      this.titleName = 'Add Project';
    }
  }

  get formControls() { return this.projectForm.controls; }

  toppings = new FormControl('');
  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

  //#region Submit After Add and Edit Project

  async submit() {
    this.clicked = true;
    this.isSubmitted = true;
    if (this.projectForm.invalid) {
      this.clicked = false;
      return;
    }

    const formData = new FormData();

    this.projectDetails = this.projectForm.value;
    formData.set('projectName', this.projectDetails.projectName);
    formData.set('projectDesc', this.projectDetails.projectDesc);


    this.projectDetails.templateBoqNum = this.projectForm.value?.templateBoqNum;
    formData.set('templateBoqNum', this.projectDetails?.templateBoqNum);

    if (this.projectForm.value?.templateBoqNum !== '1') {
      const boqFile = this.boqTemplateFiles.find(f => f.name === this.projectForm.value?.templateBoqNum);
      if (boqFile !== undefined) {
        this.projectForm.patchValue({
          templateFile: boqFile
        });
        this.projectDetails.templateFile = boqFile;
        formData.set('templateFile', boqFile, boqFile.name);
      }
    }
   
    
    this.projectDetails.projectStageInd = Number(this.projectForm.value.projectStageInd);
    formData.set('projectStageInd', this.projectDetails.projectStageInd.toString());

    var datestr = new Date(this.projectDetails.startDate).toDateString();
    formData.append("startDate", datestr);
    var datestr = new Date(this.projectDetails.endDate).toDateString();
    formData.append("endDate", datestr);

    

    if (this.dataid) {
      this.projectDetails.projectNum = this.id;
      this.projectDetails.modifiedBy = this.help.getitem('fname');
      formData.set('projectNum', this.projectDetails.projectNum.toString());
      formData.set('modifiedBy', this.projectDetails.modifiedBy);
    } else {
      this.projectDetails.createdBy = this.help.getitem('fname');
      formData.set('createdBy', this.projectDetails.createdBy);
    }
    this.spinner.show();
    this.errorMessage = null;
    if(this.dataid)
    {
      this.authi.updateProject(this.id, formData).subscribe((res: any) => {
        console.log(res);
        this.updateAfterProjectEdit(res);
      }, (err: any) => {
        console.log(err);
      });
    }
    else
    {
    this.authi.addProject(formData).subscribe((res: any) => {
      this.updateAfterProjectEdit(res);
    }, (err: any) => {
      console.log(err);
    });
  }
  }

  updateAfterProjectEdit(res:any)
  {
    
    try {
      if (res.succeeded === true) {
        if (!this.dataid) {
          this.projectDetails.projectNum = res.data.projectNum;
          this.projectDetails.clientName = res.data.clientName;
        }
        this.spinner.hide();
        this.dialogref.close({ event: 'submit', data: this.projectDetails });
       this.dialog.open(AlertBoxComponent, {
          disableClose: true,
          data: { res },
          width: '500px',
        });
      } else {
        this.errorMessage = res.message;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.clicked = false;
      this.spinner.hide();
    }
  }
  //#endregion

  //#region Get Project Data For Edit Project

  getProjectdata(id: number) {
    this.authi.getProjectData(id).subscribe((res: any) => {
      this.id = res.data.projectNum;
      this.projectDetails = res.data;
    
    }, (err: any) => {
      console.log(err);
    });
  }
  //#endregion

  //#endregion

  //#region Close Dialog Box

  closeDialoge() {
    this.dialogref.close({ event: 'cancel' });
  }
  //#endregion

  uploadFile($event) {
    this.boqTemplateFiles.push($event.target.files[0]);
    this.boqTemplates = [
      ...this.boqTemplates.slice(0, this.boqTemplates.length - 1),
      { name: $event.target.files[0].name, value: $event.target.files[0].name },
      { name: 'Upload a file', value: 'upload-file-option' }
    ];
    this.projectForm.patchValue({
      templateBoqNum: $event.target.files[0].name
    });
  }

  onBoqTemplateChange($event) {
    if ($event.target.value === 'upload-file-option') {
      const uploader = this.elRef.nativeElement.querySelector('#template-file-uploader');
      uploader.click();
    }
  }

  dateLessThanValidator(dateField1: string, dateField2: string, validatorField:
    { [key: string]: boolean }): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      const date1 = c.get(dateField1).value;
      const date2 = c.get(dateField2).value;
      if ((date1 !== null && date2 !== null) && date1 > date2) {
        return validatorField;
      }
      return null;
    };
  }

  removeTemplateBoqNumValidator() {
    this.projectForm.get('templateBoqNum').clearValidators();
    this.projectForm.get('templateBoqNum').disable();
    this.projectForm.get('templateBoqNum').updateValueAndValidity();
  }

 
}
