import {Helpers} from '../Helpers';
import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class BasicAuth implements HttpInterceptor {
  constructor(private help: Helpers) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.help.getitem('token');
    if (token) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,

          //  'Content-Type': 'application/json',
        }
      });

    }
    return next.handle(req);
  }
}
