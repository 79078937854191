import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../Services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Helpers } from 'src/app/helpers/Helpers';
@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements  OnInit {
  date: Date = new Date();
  public isCollapsed = true;
  token: string;
  email: string;
  setpasswordForm: FormGroup;
  constructor(
    private router: Router,
    private helper: Helpers,
    private activeroute: ActivatedRoute,
    private formbuilder: FormBuilder,
    private authi: AuthenticationService,
    private spinner: NgxSpinnerService,
  ) {

    this.activeroute.queryParams.subscribe(param => {
      if (param) {
        this.token = param.token;
        this.email = param.email;
      }
    });

  


  }

  ngOnInit() {

    const html = document.getElementsByTagName('html')[0];
    html.classList.add('auth-layout');
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('bg-default');
    this.router.events.subscribe((_event) => {
      this.isCollapsed = true;
    });

    this.setpasswordForm = this.formbuilder.group({
      newpassword: ['', Validators.required],
      cpassword: ['', Validators.required]
    });
  }

  get formControls() { return this.setpasswordForm.controls; }

  //#region  Submit

  submit() {
    if (this.setpasswordForm.invalid) {
      return;
    }
    let pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*#?&]{8,}$/;
    if(!pattern.test(this.setpasswordForm.value.newpassword))
    {
      alert('Password should contain a minimum of  8 characters, at least one uppercase letter, one lowercase letter, one number and one special character');
      return;
    }
    if (this.setpasswordForm.value.newpassword != this.setpasswordForm.value.cpassword) {
      alert('Password does not match');
      return;
    } else {
      this.spinner.show();
      
    this.authi.verifiedToken(this.token, this.email, this.setpasswordForm.value.newpassword).subscribe((res: any) => {
      this.spinner.hide();
      this.helper.logout();   
      alert("Password set succesfully. Please login to continue");
   
    });
     
    }
  }

  //#endregion

 //#region ngOnDestory

  ngOnDestroy() {
    const html = document.getElementsByTagName('html')[0];
    html.classList.remove('auth-layout');
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('bg-default');
  }

//#endregion
}
