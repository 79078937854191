import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../Services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  date: Date = new Date();
  public isCollapsed = true;
  token: string;
  email: string;
  setpasswordForm: FormGroup;
  constructor(
    private router: Router,
    private activeroute: ActivatedRoute,
    private formbuilder: FormBuilder,
    private authi: AuthenticationService,
    private spinner: NgxSpinnerService,
  ) {

    this.activeroute.queryParams.subscribe(param => {
      if (param) {
        this.token = param.token;
        this.email = param.email;
      }
    });

    //#region Verify Token

   

    //#endregion


  }

  ngOnInit() {
    const html = document.getElementsByTagName('html')[0];
    html.classList.add('auth-layout');
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('bg-default');
    this.router.events.subscribe((_event) => {
      this.isCollapsed = true;
    });

    this.setpasswordForm = this.formbuilder.group({
      newpassword: ['', Validators.required],
      cpassword: ['', Validators.required]
    });
  }

  get formControls() { return this.setpasswordForm.controls; }

  //#region  Submit

  submit() {
    if (this.setpasswordForm.invalid) {
      return;
    }
    if (this.setpasswordForm.value.newpassword != this.setpasswordForm.value.cpassword) {
      alert('Password does not match');
      return;
    } else {
      this.spinner.show();
      this.authi.setPassword(this.token, this.email, this.setpasswordForm.value.newpassword, this.setpasswordForm.value.cpassword).subscribe((res: any) => {
        this.spinner.hide();       
        
          this.router.navigateByUrl('/login');
        
      });
    }
  }

  //#endregion

 //#region ngOnDestory

  ngOnDestroy() {
    const html = document.getElementsByTagName('html')[0];
    html.classList.remove('auth-layout');
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('bg-default');
  }

//#endregion
}
