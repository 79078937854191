import {Helpers} from '../../helpers/Helpers';
import {Router} from '@angular/router';
import {Clientdetails} from '../../Models/Clientdetails';
import {AdminService} from 'src/app/admin/admin-service/admin.service';
import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NgxSpinnerService} from 'ngx-spinner';
import {AlertBoxComponent} from '../alertbox/alert-box.component';

@Component({
  selector: 'app-addclient',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss']
})
export class AddClientComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private formbuilder: FormBuilder,
    private authi: AdminService,
    private router: Router,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public dataid: any,
    private help: Helpers,
    private dialogref: MatDialogRef<AddClientComponent>,
  ) { }

  clicked = false;
  titleName: string;
  dataclient: Clientdetails;
  clientForm: FormGroup;
  isSubmitted = false;
  show = false;

  ngOnInit() {
    this.clientForm = this.formbuilder.group({
      clientName: ['', Validators.required],
      clientDesc: ['', Validators.required],
      phone: ['', Validators.required],
      address1: ['', Validators.required],
      address2: [''],
      address3: [''],
      state: ['', Validators.required],
      postalCode: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{4}$')]],
      statusInd: ['1', Validators.required]
    });
    this.dataclient = {};
    if (this.dataid) {
      this.titleName = 'Edit Client ';
      this.getdata(this.dataid.id);

    } else {
      this.show = false;
      this.titleName = 'Add Client';
    }
  }

  get formControls() { return this.clientForm.controls; }

  //#region Submit After Add and Edit Client
  submit() {
    this.clicked = true;
    this.isSubmitted = true;
    if (this.clientForm.invalid) {
      this.clicked = false;
      return;
    }
    this.dataclient = this.clientForm.value;
    this.dataclient.phone = Number(this.clientForm.value.phone);
    this.dataclient.postalCode = Number(this.clientForm.value.postalCode);
    this.dataclient.statusInd = Number(this.clientForm.value.statusInd);
    if (this.dataid) {
      this.dataclient.clientNum = this.dataid.id;
      this.dataclient.modifiedBy = this.help.getitem('fname');
    } else {
      this.dataclient.createdBy = this.help.getitem('fname');
    }
    this.spinner.show();
    this.authi.addClient(this.dataclient).subscribe((res: any) => {
        if (res.succeeded === true) {
          if (!this.dataid) {
            this.dataclient.clientNum = res.data.clientNum;
          }
          this.spinner.hide();
          this.dialogref.close({event: 'submit', data: this.dataclient});
          this.dialog.open(AlertBoxComponent, {
            disableClose: true,
            data: {res},
            width: '500px',
          });
        }
      },
      (error: any) => {
        console.log(error);
      });
  }
  //#endregion

  //#region  Get Client Data For Edit Client
  getdata(id: number) {
    this.show = true;
    this.authi.getClientData(id).subscribe((res: any) => {
        this.dataclient = res.data;
      },
      (error: any) => {
        console.log(error);
      });
  }
  //#endregion

  //#region Close Dialog Box
  closedialoge() {
    this.dialogref.close({ event: 'cancel' });
  }
  //#endregion


}
