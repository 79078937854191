import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Constant } from "../shared/Constant";
import { AuthGaurdGuard } from "../helpers/Auth-gurad/auth-gaurd.guard";
import { ProjectSetupResponse } from "../Models/project-setup";
import { BridgeSetupResponse } from "../Models/bridge-setup";

@Injectable({ providedIn: 'root' })
export class ProjectService {

    constructor(private http: HttpClient, private authGuard:AuthGaurdGuard) {        
    }

    getProjectInfo<T>(id: number): Observable<any[]> {      
        return this.http.get<[]>(Constant.webapi + `Project/${id}/GetProjectInfo`);
    }

    deleteProject<T>(projectNum: number): Observable<any[]> {      
        return this.http.delete<[]>(Constant.webapi + `Project/${projectNum}/delete-project`).pipe();
    }

    getProjectClientInfo<T>(id: number): Observable<any[]> {      
        return this.http.get<[]>(Constant.webapi + `Project/${id}/project-client`);
    }
    getProjectSetup(id: number | string): Observable<ProjectSetupResponse> {      
        return this.http.get<ProjectSetupResponse>(Constant.webapi + `Projects/${id}/project-setup`);
    }

    getBridgesSetup(id: number | string): Observable<BridgeSetupResponse> {      
        return this.http.get<BridgeSetupResponse>(Constant.webapi + `Projects/${id}/bridges-setup`);
    }

}
