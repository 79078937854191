import * as _ from 'lodash';

export interface IBOQCode {
  // 'boqName': string;
  // 'boqClassificationInd': number;
  // 'boq_header_num': number;
  // 'clientNum': number;
  // 'infrastructureNum': number;
  // 'milestoneNum': number;
  // 'parentBoqNum': null | number;
  // 'projectStageInd': number;
  // 'statusInd': number;
  // 'boqNum': number;
  'projectBoqSectionDisplayOrder': number;
  'boqSectionCd': string;
  'boqSectionName': string;
  'boqSectionDesc': string;
  'boqSectionNum': number;
  'boqCodeCd': string;
  'BoqCodeName': string;
  'boqCodeDesc': string;
  'boqCodeNum': number;
  'projectBoqCodeDisplayOrder': number;
  // 'isAdded': number | null;
  'metricInd': number;
  'quantityAmt': number;
  // 'masterCodeNum': number | null;
  'rate': number;
  'modifiedDt': string;
  'modifiedBy': string;
  // 'document_guid': null | string;
  // 'document_title': null | string;
  unit?: string;
  showRow?: boolean;
  masterRate?: number;
  totalAmount?: number;
  projectMasterRate?: number;
}

export interface ICode {
  boqCodeCd: string;
  BoqCodeName: string;
  boqCodeDesc: string;
  boqCodeNum: number;
  projectBoqCodeDisplayOrder: number;
  metricInd?: number;
  quantityAmt?: number;
  rate?: number;
  unit?: string;
  showRow: boolean;
  order: string;
  modifiedDt?: string;
  masterRate?: number;
  totalAmount?: number;
  transformType?: TransformType;
  projectMasterRate?: number;
}

export class BOQCode implements ICode {
  get showRow(): boolean {
    return this.isValidRow;
  }

  get isValidRow(): boolean {
    return (_.isNumber(this.quantityAmt) && this.quantityAmt > 0);   
  }

  // tslint:disable-next-line:variable-name
  BoqCodeName: string;
  boqCodeCd: string;
  boqCodeDesc: string;
  boqCodeNum: number;
  isAdded: number;
  metricInd: number;
  order: string;
  projectBoqCodeDisplayOrder: number;
  quantityAmt: number;
  rate: number;
  unit: string;
  modifiedDt?: string;
  masterRate?: number;
  totalAmount?: number;
  transformType?: TransformType;
  projectMasterRate?: number;

  constructor(code: ICode) {
    this.BoqCodeName = code.BoqCodeName;
    this.boqCodeCd = code.boqCodeCd;
    this.boqCodeDesc = code.boqCodeDesc;
    this.boqCodeNum = code.boqCodeNum;
    this.metricInd = code.metricInd;
    this.order = code.order;
    this.projectBoqCodeDisplayOrder = code.projectBoqCodeDisplayOrder;
    this.quantityAmt = code.quantityAmt;
    this.rate = code.rate;
    this.unit = code.unit;
    this.modifiedDt = code.modifiedDt;
    this.masterRate = code.masterRate;
    this.totalAmount = code.totalAmount;
    this.transformType = code.transformType;
    this.projectMasterRate = code.projectMasterRate;
  }

  public get totalCost(): number {
    if(this.transformType === TransformType.Project){
      return this.totalAmount;
    }
    else{
      return this.rate * this.quantityAmt;
    }    
  }

  public get changeInInfrastructure(): boolean {
    if(this.transformType == TransformType.Project)
      return false;
    else if (this.transformType == TransformType.Infrastructure){
     if(this.projectMasterRate == this.rate && this.quantityAmt >= 0){
      return false;
     }else{
      return true;
     }    
      // return !_.isNil(this.modifiedDt); 
    }
  }
}

export interface ISection {
  projectBoqSectionDisplayOrder: number;
  boqSectionCd: string;
  boqSectionName: string;
  boqSectionDesc: string;
  boqSectionNum: number;
  codes: Array<BOQCode>;
}

export class BoqSection implements ISection {
  boqSectionCd: string;
  boqSectionDesc: string;
  boqSectionName: string;
  boqSectionNum: number;
  codes: Array<BOQCode>;
  projectBoqSectionDisplayOrder: number;

  constructor(section: ISection) {
    this.boqSectionCd = section.boqSectionCd;
    this.boqSectionDesc = section.boqSectionDesc;
    this.boqSectionName = section.boqSectionName;
    this.boqSectionNum = section.boqSectionNum;
    this.codes = section.codes.map(c => new BOQCode(c));
    this.projectBoqSectionDisplayOrder = section.projectBoqSectionDisplayOrder;
  }

  get shouldShowSection(): boolean {
    return this.withAmountCodes.length !== 0;
  }

  get validCodes(): BOQCode[] {
    return this.codes.filter(c => c.rate || c.quantityAmt);
  }

  get hasValidCodes(): boolean {
    return this.validCodes.length !== 0;
  }

  get withAmountCodes(): BOQCode[] {
    return this.codes.filter(c => c.quantityAmt > 0);
  }

  get totalCostOfSection(): number {
    return this.codes.reduce((previousValue, currentValue) => {
      return previousValue + currentValue.totalCost;
    }, 0);
  }
}


export enum TransformType {
  Infrastructure = 1,
  Project = 2  
}