import {ResetPasswordComponent} from './public/reset-password/reset-password.component';
import {AuthGaurdGuard} from 'src/app/helpers/Auth-gurad/auth-gaurd.guard';
import {AdminLayoutComponent} from './admin/admin-layout/admin-layout.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy, RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './public/login/login.component';
import {ForgetPasswordComponent} from './public/forget-password/forget-password.component';
import {ErrorPageComponent} from './public/error-page/error-page.component';
import {BoqDetailComponent} from './pages/boqlink/boq-detail.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { VerifyComponent } from './public/verify/verify.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forget-password',
    component: ForgetPasswordComponent
  },
  {
    path: 'set-password',
    component: ResetPasswordComponent
  },
  {
    path: 'verify',
    component: VerifyComponent,
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  }, 
  {
    path: 'admin',
    component: AdminLayoutComponent,
    canActivateChild: [AuthGaurdGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
      }
    ]
  },
  {
    path: '**',
    component: ErrorPageComponent
  }
];

export class AppRouteReuseStrategy extends RouteReuseStrategy {
  /*
   * Whether the given route should detach for later reuse.
   * Always returns false for `BaseRouteReuseStrategy`.
   * */
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  /**
   * A no-op; the route is never stored since this strategy never detaches routes for later re-use.
   */
  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
  }

  /** Returns `false`, meaning the route (and its subtree) is never reattached */
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  /** Returns `null` because this strategy does not store routes for later re-use. */
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }

  /**
   * Determines if a route should be reused.
   * This strategy returns `true` when the future route config and current route config are
   * identical.
   */
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    /*Why not reuse route if component === BoqDetailComponent?
    * Because else we cannot refresh the BoqDetailComponent page. Huhm, what does it mean?
    * In BoqDetailComponent, whenever user changes the project (using the drop down list),
    * we want to refresh the page again to load from server all the BOQCodes of the newly selected
    * infrastructure. Angular would stops us from doing that because the page we want to navigate to
    * has same URL as the current page. Therefore, we need to tell Angular to make exception for
    * BOQDetailComponent.
    * */
    if (future.component === BoqDetailComponent) {
      return false;
    }
    return future.routeConfig === curr.routeConfig;
  }
}

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: false,
      onSameUrlNavigation: 'reload',
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
