import {Helpers} from '../Helpers';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChild, Router} from '@angular/router';
import { AdminService } from 'src/app/admin/admin-service/admin.service';
import { Observable, of,  } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGaurdGuard implements CanActivateChild {
  projectId: string;
  constructor(private help: Helpers, private router: Router, private adminService: AdminService) {
  }

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
    this.projectId = route.paramMap.get("projectId");
    if(!this.projectId)
    {
      this.projectId = route.queryParamMap.get("projectId");
    }

    if (this.help.getitem('token') != null) {  

      if(this.help.getitem('role') === '3' && route?.url[0]?.toString() === 'project-boq'){
        return of(false);
      }
        return of(true);
      
      
    } else {
      this.router.navigateByUrl('/login');
      return of(false);
    }
  }

}
