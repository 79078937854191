import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { IInfrastructureDetail } from '../pages/graph/IInfrastructureDetail';
import { IProjectDetail } from '../pages/graph/IProjectDetail';
import { IGraphQueryParam } from '../Models/Graph';
import { IAuthenticationResult } from '../public/Services/authentication.service';
import { IBoqCompareRouterParam, ISnapshotCompareRouterParam } from '../Models/boq-compare-router-param';
import { RoleType } from '../types/role-type';
import { UserRole } from '../Models/user-role';
import { IBoqDetailFromOptionsParam } from '../Models/boq-detail';

export interface IBoqLink {
  proname: string;
  clientname: string;
  infraName: string;
  infrastructureNum: number;
  proNum: number;
  clientNum: number;
  boqnum: number;
  packagename: string;
  projectStageInd?: number;
  fromOptions?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class Helpers {
  admin = RoleType.Admin;
  authInfo: IAuthenticationResult;

  public get isLoggedIn(): boolean {
    return !!this.authenticationInfo.id;
  }

  public get isAdmin(): boolean {
    return this.authenticationInfo.isAdminInd;
  }

  public  isAdminForClient(clientId: number | string): boolean {
    return this.authenticationInfo.clientId == clientId;
  }

  setRole(userRoles: UserRole[]) {
    this.authInfo.role = userRoles;
    this.setitem("roles", JSON.stringify(userRoles));
  }

  public hasProjectWrite(projectId: number | string): boolean {
    let role = this.authenticationInfo.role.find(c => c.projectId == projectId);
    if (role && role.role === 'Writer')
      return true;
    else
      return false;
  }

  public get userName(): string {
    return this.getitem('fname');
  }

  public get userId(): string {
    return this.getitem('id');
  }

  public get projectNum(): string {
    return JSON.parse(this.getitem('boqlink')).proNum;
  }

  constructor(private router: Router) {
  }

  getitem(key) {
    return localStorage.getItem(key);
  }

  setitem(key, val) {
    localStorage.setItem(key, val);
  }

  removeitem(key) {
    localStorage.removeItem(key);
  }

  public navigateToProjectSnapshotPage(
    projectName: string,
    projectDetail: IProjectDetail
  ) {
    return this.navigateToBoqDetailPage(
      {
        project_name: projectName,
        infrastructure_num: -1,
        infrastructure_name: projectName,
        package_name: projectName
      }, projectDetail, -1
    );
  }

  public navigateToBoqDetailPage(
    infrastructureDetail: Pick<IInfrastructureDetail,
      'project_name' | 'infrastructure_name' | 'package_name' | 'infrastructure_num'>,
    projectDetail: IProjectDetail,
    boqNum: number,
    queryParams?: IBoqDetailFromOptionsParam) {
    
    const boqlink = {
      proname: infrastructureDetail.project_name,
      clientname: projectDetail.clientName,
      infraName: infrastructureDetail.infrastructure_name,
      proNum: projectDetail.projectNum,
      clientNum: projectDetail.clientNum,
      projectStageInd: projectDetail.projectStageInd,
      boqnum: boqNum,
      packagename: infrastructureDetail.package_name,
      infrastructureNum: infrastructureDetail.infrastructure_num
    } as IBoqLink;

    this.setitem('boqlink', JSON.stringify(boqlink));
    if(queryParams && queryParams.fromOptions) {
      console.log("queryparams fromoptions " + queryParams.fromOptions);
      return this.router.navigate([`/admin/projects/${projectDetail.projectNum}/boqlink/${boqNum}`], { queryParams });
    } else {
      return this.router.navigate([`/admin/projects/${projectDetail.projectNum}/boqlink/${boqNum}`]);
    }
  }

  public get authenticationInfo(): IAuthenticationResult {
    if (!this.authInfo || !this.authInfo.id) {
      this.authInfo = {
        id: this.getitem('id'),
        firstName: this.getitem('fname'),
        isAdminInd: this.getitem('clientId') !== undefined && +this.getitem('clientId') !== -1,
        jwtToken: this.getitem('token'),
        role: JSON.parse(this.getitem('roles')),
        clientId:this.getitem('clientId') 
      };
    }

    return this.authInfo;

  }

  public get dashboardLink(): string {
    return '/admin/dashboard';
  }

  public logout() {
    localStorage.clear();  
    this.authInfo = undefined;    
    this.router.navigate(['/login']);

  }

  public notFound() {
    return this.router.navigateByUrl('/not-found');
  }

  public navigateToBoqComparePage(infrastructureID: number, queryParams: IBoqCompareRouterParam) {
    console.log("queryparams fromoptions " + queryParams.fromOptions);
    return this.router.navigate([`/admin/${queryParams.projectId}/boqcompare/${infrastructureID}`], {
      queryParams
    });
  }

  public navigateToSnapshotComparePage(queryParams: ISnapshotCompareRouterParam) {
    return this.router.navigate([`/admin/${queryParams.projectId}/snapshotcompare`], {
      queryParams
    });
  }
  public getProjectStageInd(projectStageInd: number): string {
    if (projectStageInd === 1)
      return 'Concept Design';
    else if (projectStageInd === 2)
      return 'Tender Design';
    else if (projectStageInd === 3)
      return 'Preliminary Design';
    else if (projectStageInd === 4)
      return 'Detail Design';
    else if (projectStageInd === 5)
      return 'Issued For Construction';
  }

}

export function convertProjectDetailToQueryParam(projectDetail: IProjectDetail): IGraphQueryParam {
  return { ...projectDetail, projectId: projectDetail.projectNum };
}

export function convertBoqLinkToProjectDetail(boqLink: IBoqLink): IProjectDetail {
  return {
    projectName: boqLink.proname,
    projectNum: boqLink.proNum,
    clientName: boqLink.clientname,
    clientNum: boqLink.clientNum,
    projectStageInd: boqLink.projectStageInd
  };
}
