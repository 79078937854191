import {Injectable} from '@angular/core';
import {BOQCode, BoqSection, IBOQCode, ICode, ISection, TransformType} from '../Models/IBoqHeader';
import * as _ from 'lodash';
import {getBoqOrder} from '../helpers/utils';
import {UnitTable} from '../shared/Unit';

@Injectable({
  providedIn: 'root'
})
export class BoqDetailService {

  constructor() {
  }

  private static sortCodes(a, b) {
    if (a.order.match(/^\d(\.?\d)*$/g) === null || b.order.match(/^\d(\.?\d)*$/g) === null) {
      return a.projectBoqCodeDisplayOrder - b.projectBoqCodeDisplayOrder;
    }

    if (a.order === b.order) {
      return 0;
    }

    const aVersion = a.order.split('.');
    const bVersion = b.order.split('.');
    const aMajor = aVersion[0];
    const bMajor = bVersion[0];
    if (Number(aMajor) !== Number(bMajor)) {
      return Number(aMajor) - Number(bMajor);
    }
    const aMinor = aVersion.length > 1 ? Number(aVersion[1]) : 0;
    const bMinor = bVersion.length > 1 ? Number(bVersion[1]) : 0;

    if (aMinor !== bMinor) {
      return aMinor - bMinor;
    }

    const aIncrement = aVersion.length > 2 ? Number(aVersion[2]) : 0;
    const bIncrement = bVersion.length > 2 ? Number(bVersion[2]) : 0;

    return aIncrement - bIncrement;
  }

  public handleScroll() {
    const header = document.getElementById('header');
    const windowScroll = window.pageYOffset;
    if (header) {
      if (windowScroll > 500) {
        header.classList.add('sticky');
      } else {
        header.classList.remove('sticky');
      }
    }
  }

  public transformToSections(boqCodes: IBOQCode[], transformType?: TransformType): { totalCost: number; sections: BoqSection[] } {
    let totalCost = 0;
    const sections = [] as BoqSection[];
    boqCodes.forEach(item => {
      if(transformType === TransformType.Infrastructure){
        totalCost = totalCost + (item.rate * item.quantityAmt);        
      }
      else{
        totalCost = totalCost + item.totalAmount;
      } 

      if (!sections.some(section => section.boqSectionNum === item.boqSectionNum)) {
        const newSection = new BoqSection({
          projectBoqSectionDisplayOrder: item.projectBoqSectionDisplayOrder,
          boqSectionCd: item.boqSectionCd,
          boqSectionName: item.boqSectionName,
          boqSectionDesc: item.boqSectionDesc,
          boqSectionNum: item.boqSectionNum,
          codes: boqCodes
            .filter(d => d.boqSectionNum === item.boqSectionNum)
            .map(code => {
              const order = getBoqOrder(code.boqCodeCd, item.boqSectionCd);

              return {
                boqCodeCd: code.boqCodeCd,
                BoqCodeName: code.BoqCodeName,
                boqCodeDesc: code.boqCodeDesc,
                boqCodeNum: code.boqCodeNum,
                projectBoqCodeDisplayOrder: code.projectBoqCodeDisplayOrder,
                unit: code.metricInd ? UnitTable.filter(x => x.unit_id === code.metricInd)[0].unit_name : null,
                quantityAmt: code.quantityAmt ? code.quantityAmt : 0,
                rate: (transformType === TransformType.Infrastructure) ? (code.rate ? code.rate : 0) : 
                      (code.masterRate ? code.masterRate  : 0),
                showRow: //(_.isNumber(code.rate) && code.rate !== 0) || 
                         (_.isNumber(code.quantityAmt) && code.quantityAmt !== 0),                
                modifiedDt: code.modifiedDt,
                masterRate: code.masterRate ? code.masterRate  : 0,
                totalAmount: code.totalAmount ? code.totalAmount  : 0,
                projectMasterRate: code.projectMasterRate ,
                transformType: transformType,                
                order
              } as ICode;
            })
        } as ISection);

        newSection.codes.sort((a, b) => {
          return BoqDetailService.sortCodes(a, b);
        });

        sections.push(newSection);
      }
    });
    sections.sort((a, b) => {
      return a.projectBoqSectionDisplayOrder - b.projectBoqSectionDisplayOrder;
    });
    return {totalCost, sections};
  }


  public shouldShowRow(code: BOQCode, searchString: string, isThisCodeEditing: boolean, showAllData: boolean): boolean {
    /*
    By default, we only show row wih row.showRow is true (those with rate !== 0).
    If searchBoxNotEmpty, we filter those contain search string.

    If user selects showAllData, by default, we would display everything.
    If searchBoxNotEmpty, we filter those contain search string.
    * */
    if (!showAllData) { // only display some rows
      if (this.searchBoxNotEmpty(searchString)) {
        return this.isCodeMatchSearchString(code, searchString);
      } else {
        /*only display rows with Rate and Quantity by default. However, if the row is editing, we also show it!*/
        return code.showRow || isThisCodeEditing;
      }
    } else { // display ALL rows
      if (this.searchBoxNotEmpty(searchString)) {
        return this.isCodeMatchSearchString(code, searchString);
      } else {
        return true; //if show all is selected, should also rows with quantity === zero \
        // and rows with rate===nul
      }
    }
  }

  private searchBoxNotEmpty = (searchString: string): boolean => !_.isNil(searchString) && !_.isEmpty(searchString);

  private isBoqCodeDescContainSearchString = (
    code: BOQCode,
    searchString: string): boolean => code.boqCodeDesc.toLowerCase().search(searchString.toLowerCase()) !== -1

  private isBoqCodeContainSearchString = (
    code: BOQCode,
    searchString: string): boolean => code.boqCodeCd.toLowerCase().search(searchString.toLowerCase()) !== -1

  private isCodeMatchSearchString(code: BOQCode, searchString: string): boolean {
    return this.isBoqCodeContainSearchString(code, searchString) || this.isBoqCodeDescContainSearchString(code, searchString);
  }
}
