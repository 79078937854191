import { Component, OnInit } from '@angular/core';
import Chart from 'node_modules/chart.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { chartExample1, chartExample2, chartOptions, parseOptions } from 'src/app/variables/charts';

@Component({
  selector: 'app-prject-summary-chart',
  templateUrl: './prject-summary-chart.component.html',
  styleUrls: ['./prject-summary-chart.component.scss']
})
export class PrjectSummaryChartComponent implements OnInit {

  show = false;
  showdropdown = false;
  projectName;
  constructor() { }

  ngOnInit() {
    this.chartdatanull();
  }

  show2dropdown() {
    this.showdropdown = true;
  }

  showMethod(name) {

    const pname = name.target.value;
    if (pname === '') {
      this.show = false;
      this.projectName = '';
      this.chartdatanull();
    } else {
      this.show = true;
      this.projectName = name.target.value;

      this.chartdata();
    }



  }

  chartdata() {
    const data123 = {
      labels: ['Total Budget', 'Total Direct Cost', 'Praxis Value Engineered'],
      datasets: [
        {
          label: 'Budget',
          data: [100, 30, 70]
        }
      ]
    }
    const chartOrders = document.getElementById('chart-orders');
    parseOptions(Chart, chartOptions());
    const ordersChart = new Chart(chartOrders, {
      type: 'horizontalBar',
      data: data123
    });
  }
  chartdatanull() {
    const data123 = {
      labels: ['Total Budget', 'Total Direct Cost', 'Praxis Value Engineered'],
      datasets: [
        {
          label: 'Budget',
          data: [0, 0, 0]
        }
      ]
    }
    const chartOrders = document.getElementById('chart-orders');
    parseOptions(Chart, chartOptions());
    const ordersChart = new Chart(chartOrders, {
      type: 'horizontalBar',
      data: data123
    });
  }
}
