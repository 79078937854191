export enum EPackageClassification {
  Base,
  Option
}

export enum EInfrastructureClassification {
  Base,
  Option,
  BaseOption
}

export enum EPackageOptionStatus {
  Review,
  Accepted,
  Declined,
  NotApplicable
}

export enum EBoqClassification {
  Base = 1,
  BaseVerified,
  ValueEngineered,
  Option,
  BaseOption
}

export function getBoqClassificationString(v: EBoqClassification) {
  switch (v) {
    case EBoqClassification.Base:
      return 'Base';
    case EBoqClassification.BaseVerified:
      return 'Base Verified';
    case EBoqClassification.ValueEngineered:
      return 'Value Engineered';
    case EBoqClassification.Option:
      return 'Option';
    case EBoqClassification.BaseOption:
      return 'Base Option';
    default:
      throw new Error(`Invalid boqClassificationString: ${v}`);
  }
}

export function getInfrastructureClassification(infrastructureClassificationInd: number): EInfrastructureClassification {
  switch (infrastructureClassificationInd) {
    case 0:
    case null:
    case undefined:
      return EInfrastructureClassification.Base;
    case 1:
      return EInfrastructureClassification.Option;
    case 2:
      return EInfrastructureClassification.BaseOption;
    default:
      throw new Error(`Invalid infrastructureClassificationInd: ${infrastructureClassificationInd}`);
  }
}
