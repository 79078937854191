/*!

=========================================================
* Argon Dashboard Angular - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-angular
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-angular/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import * as Chart from 'chart.js';
import {IDoughnutCenterConfig} from './app/Models/doughnut-center-config';

if (environment.production) {
  enableProdMode();
}

function getLines(txt: string, ctx: CanvasRenderingContext2D, elementWidth: number): { line: string; lines: string[] } {
  const words = txt.split(' ');
  let line = '';
  const lines = [];

  // Break words up into multiple lines if necessary
  for (let n = 0; n < words.length; n++) {
    const testLine = line + words[n] + ' ';
    const metrics = ctx.measureText(testLine);
    const testWidth = metrics.width;
    if (testWidth > elementWidth && n > 0) {
      lines.push(line);
      line = words[n] + ' ';
    } else {
      line = testLine;
    }
  }
  return {line, lines};
}

function drawCenterText(chart: Chart) {
  const doughnutCenterConfig = chart.config.options.plugins.doughnutCenter as IDoughnutCenterConfig;
  if (doughnutCenterConfig) {
    // Get ctx from string
    const ctx = chart.ctx;

    // Get options from the center object in options
    const centerConfig = doughnutCenterConfig;
    const fontStyle = centerConfig.fontStyle || 'Arial';
    const txt = centerConfig.text;
    const color = centerConfig.color || '#000';
    const maxFontSize = centerConfig.maxFontSize || 75;
    const sidePadding = centerConfig.sidePadding || 20;
    const innerRadius = (chart as any).innerRadius;
    const sidePaddingCalculated = (sidePadding / 100) * (innerRadius * 2);
    // Start with a base font of 30px
    ctx.font = '30px ' + fontStyle;

    // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
    const stringWidth = ctx.measureText(txt).width;
    const elementWidth = (innerRadius * 2) - sidePaddingCalculated;

    // Find out how much the font can grow in width.
    const widthRatio = elementWidth / stringWidth;
    const newFontSize = Math.floor(30 * widthRatio);
    const elementHeight = (innerRadius * 2);

    // Pick a new font size so it will not be larger than the height of label.
    let fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
    let minFontSize = centerConfig.minFontSize;
    const lineHeight = centerConfig.lineHeight || 25;
    let wrapText = false;

    if (minFontSize === undefined) {
      minFontSize = 20;
    }

    if (minFontSize && fontSizeToUse < minFontSize) {
      fontSizeToUse = minFontSize;
      wrapText = true;
    }

    // Set font settings to draw it correctly.
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
    let centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
    ctx.font = fontSizeToUse + 'px ' + fontStyle;
    ctx.fillStyle = color;

    if (!wrapText) {
      ctx.fillText(txt, centerX, centerY);
      return;
    }

    const {line, lines} = getLines(txt, ctx, elementWidth);

    // Move the center up depending on line height and number of lines
    centerY -= (lines.length / 2) * lineHeight;

    for (const item of lines) {
      ctx.fillText(item, centerX, centerY);
      centerY += lineHeight;
    }
    //Draw text in center
    ctx.fillText(line, centerX, centerY);
  }
}

//Configure ChartJS with the ability to draw at doughnut center
Chart.pluginService.register({
  beforeDraw(chart) {
    drawCenterText(chart);
  }
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
