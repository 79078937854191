export const UnitTable = [
  { unit_id: 1, unit_name: 'lump sum' },
  { unit_id: 2, unit_name: 'provisional sum' },
  { unit_id: 3, unit_name: 'day' },
  { unit_id: 4, unit_name: 'week' },
  { unit_id: 5, unit_name: 'm3' },
  { unit_id: 6, unit_name: 'each' },
  { unit_id: 7, unit_name: 'm2' },
  { unit_id: 8, unit_name: 'm' },
  { unit_id: 9, unit_name: 't' },
  { unit_id: 10, unit_name: 'litre' },
  { unit_id: 11, unit_name: 'man-day' },
  { unit_id: 12, unit_name: 'kilolitre' },
  { unit_id: 13, unit_name: 'hour' },
  { unit_id: 14, unit_name: 'month' },
  { unit_id: 15, unit_name: 'AWS month' },
  { unit_id: 16, unit_name: 'km' },
  { unit_id: 17, unit_name: 'each mix' },
];
