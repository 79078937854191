import { AddClientComponent } from './pages/add-client/add-client.component';
import { AdminLayoutComponent } from './admin/admin-layout/admin-layout.component';
import { ErrorPageComponent } from './public/error-page/error-page.component';
import { ForgetPasswordComponent } from './public/forget-password/forget-password.component';
import { LoginComponent } from './public/login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { AppRouteReuseStrategy, AppRoutingModule } from './app-routing.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BasicAuth } from './helpers/Interceptors/basic-auth';
import { ErrorInterceptor } from './helpers/Interceptors/error-intercepter.service';
import { AddProjectComponent } from './pages/add-project/add-project.component';
import { DataTablesModule } from 'angular-datatables';
import { AddPackagesComponent } from './pages/add-packages/add-packages.component';
import { AddInfrastructureComponent } from './pages/add-infrastucture/add-infrastructure.component';
import { AddMilestoneComponent } from './pages/add-milestone/add-milestone.component';
import { AddInfrastructureBOQComponent } from './pages/add-infrastucture-boq/add-infrastructure-boq.component';
import { AlertBoxComponent } from './pages/alertbox/alert-box.component';
import { FileUploadComponent } from './pages/pdfupload/file-upload.component';
import { ResetPasswordComponent } from './public/reset-password/reset-password.component';
import { DecimalPipe } from '@angular/common';
import { PrjectSummaryChartComponent } from './pages/prject-summary-chart/prject-summary-chart.component';
import { EditUserComponent } from './pages/users/edit/edit-user.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { UpsetExcelInputComponent } from './pages/manage-excel/upsert-excel-input/upsert-excel-input.component';
import { JsonFormComponent } from './pages/manage-excel/json-form/json-form.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { FileUploadModule } from 'ng2-file-upload';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ngx-toastr';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { VerifyComponent } from './public/verify/verify.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    NgxSpinnerModule,
    DataTablesModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatButtonModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    FileUploadModule,
    NgSelectModule,
    ToastrModule.forRoot()
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    ForgetPasswordComponent,
    ErrorPageComponent,
    AdminLayoutComponent,
    AddClientComponent,
    AddProjectComponent,
    AlertBoxComponent,
    AddPackagesComponent,
    AddInfrastructureComponent,
    AddMilestoneComponent,
    AddInfrastructureBOQComponent,
    FileUploadComponent,
    ResetPasswordComponent,
    PrjectSummaryChartComponent,
    EditUserComponent,
    UpsetExcelInputComponent,
    JsonFormComponent,
    FileUploaderComponent    ,
    NotFoundComponent,
    VerifyComponent
    
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuth, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy },
    DecimalPipe
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AddClientComponent,
    AddProjectComponent,
    AlertBoxComponent,
    AddPackagesComponent,
    AddInfrastructureComponent,
    AddMilestoneComponent,
    AddInfrastructureBOQComponent,
    FileUploadComponent,
    ForgetPasswordComponent,
    EditUserComponent,
    UpsetExcelInputComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
