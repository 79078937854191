import { convertProjectDetailToQueryParam, Helpers } from '../../helpers/Helpers';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { IGraphQueryParam } from 'src/app/Models/Graph';
import { AuthGaurdGuard } from 'src/app/helpers/Auth-gurad/auth-gaurd.guard';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public focus;
  public location: Location;
  public currentProjectLink: string;
  
  constructor(
    public authGuard: AuthGaurdGuard,
    location: Location,
    public router: Router,
    public helperService: Helpers
  ) {
    this.location = location;
  }



  ngOnInit() {
    //this.projectDashboardLink();
  }

  public get dashboardLink(): string {
    return this.helperService.dashboardLink;
  }

  public get projectDashboardLink(): string {
    return sessionStorage.getItem("projectDashboardLink");
  }

  get queryParam()
  {
    if(this.helperService.getitem("projectDetails"))
    {
      return convertProjectDetailToQueryParam(JSON.parse(this.helperService.getitem("projectDetails")));
    }
    else
      return  { projectId: this.authGuard.projectId };

  }
 
  public logout() {
    return this.helperService.logout();
  }

  isShowDataInput(): boolean {
    if(!this.authGuard.projectId && !this.helperService.hasProjectWrite(this.authGuard.projectId))
      return false;
    
    if (this.location.path().split('?')[0] == '/admin/graph')
      return true;
    else if (this.location.path().split('?')[0].includes('data-input'))
      return true;
    else if (this.location.path().split('?')[0] == '/admin/dashboard')
      return false;
    else
      return true;
  }

  showDashboard(): boolean {
    if (!this.authGuard.projectId)
      return false;
    else
      return true;
  }

  showOptions(): boolean {
    if (!this.authGuard.projectId)
      return false;
    else
      return true;
  }
}
