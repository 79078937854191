import { environment } from './../environments/environment.dev';
import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Helpers } from './helpers/Helpers';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Praxis App';

  constructor(private router: Router,
    private helper: Helpers) {   
    this.setUpGoogleAnalytics();
  }

  setUpGoogleAnalytics(){
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {      
       gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects
       });        

       if(this.helper.userId !== null){
        gtag('event', 'user', {
          'userId': this.helper.userId  + '_' + this.helper.userName
        });        
      }
    })
  } 
}
