import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertBoxComponent } from '../../alertbox/alert-box.component';
import { ExcelFileView } from 'src/app/Models/excel-file-view';
import { ExcelFileService } from 'src/app/serivces/excel-file.service';

@Component({
  selector: 'app-upsert-excel-input',
  templateUrl: './upsert-excel-input.component.html',
  styleUrls: ['./upsert-excel-input.component.scss']
})
export class UpsetExcelInputComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private excelFileService: ExcelFileService,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: {
      excelFile?: ExcelFileView,
      excelSheets: Array<{
        id: number;
        sheetName: string
      }>
    },
    private dialogRef: MatDialogRef<UpsetExcelInputComponent>,
  ) { }

  clicked = false;
  formTitle: string;
  userForm: FormGroup;
  isSubmitted = false;
  show = false;
  dataExcelFile: ExcelFileView;
  submitError?: string = null;

  ngOnInit(): void {
    if (this.data.excelFile) {
      this.formTitle = 'Edit Excel Input Field';
      this.dataExcelFile = {...this.data.excelFile};
    } else {
      this.show = false;
      this.formTitle = 'Add Excel Input Field';
      this.dataExcelFile = {
        id: -1,
        excelSheet: null,
        description: '',
        controlType: '',
        excelRowNumber: 0,
        excelColumnName: ''
      };
    }
    this.userForm = this.formBuilder.group({
      excelSheet: [ this.data.excelFile && this.data.excelFile.excelSheet ? this.data.excelFile.excelSheet : null, Validators.required],
      description: [this.data.excelFile ? this.data.excelFile.description : '', Validators.required],
      controlType: [this.data.excelFile ? this.data.excelFile.controlType : '', Validators.required],
      excelColumnName: [ this.data.excelFile ? this.data.excelFile.excelColumnName : '', Validators.required],
      excelRowNumber: [this.data.excelFile ? this.data.excelFile.excelRowNumber : 0, Validators.required]
    });
  }

  get formControls() { return this.userForm.controls; }

  closeDialog() {
    this.dialogRef.close({ event: 'cancel' });
  }

  submit() {
    this.clicked = true;
    this.isSubmitted = true;
    this.submitError = null;
    if (this.userForm.invalid) {
      this.clicked = false;
      return;
    }
    this.spinner.show();   
    if (!this.data.excelFile) { 
      this.excelFileService.getAddExcelInput(this.userForm.value)
      .subscribe((res: any) => {
        this.spinner.hide();
          this.clicked = false;
          this.dialogRef.close({
            event: 'submit',
            data: res,
          });
          this.dialog.open(AlertBoxComponent, {
            disableClose: true,
            width: '500px',
            data: {
              res: {
                message: 'Excel form input has been added'
              }
            }
          });
      });
    }
    else{      
      //const data = this.userForm.value;
      //data.excelSheetId = 
      this.excelFileService.updateExcelInput(this.userForm.value, this.data?.excelFile?.id)
      .subscribe((res: any) => {
        this.spinner.hide();
          this.clicked = false;
          this.dialogRef.close({
            event: 'submit',
            data: this.dataExcelFile,
          });
          this.dialog.open(AlertBoxComponent, {
            disableClose: true,
            width: '500px',
            data: {
              res: {
                message: 'Excel form input has been Updated'
              }
            }
          });
      });
    }   
  }

  compareById(itemOne: {
    id: number;
    sheetName: string
  },          itemTwo: {
    id: number;
    sheetName: string
  }) {
    return itemOne && itemTwo && itemOne.id === itemTwo.id;
  }
}
