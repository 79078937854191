import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BoqResponse } from '../Models/boq-detail';
import { IProjectSummaryResponse } from '../Models/project-summary-response';
import { Constant } from '../shared/Constant';

@Injectable({
  providedIn: 'root'
})
export class BoqService {

  constructor(private http: HttpClient) { }

  getProjectSnapshotBoq(projectId: string | number): Observable<BoqResponse>
  {
    return this.http.get<BoqResponse>(`${Constant.webapi}projects/${projectId}/GetProjectSnapshot`);
  }

  getInfrastructureBoq(projectId: string | number, boqNum: string | number): Observable<BoqResponse>
  {
    return this.http.get<BoqResponse>(`${Constant.webapi}projects/${projectId}/GetInfrastructureBOQCodeList?boq_num=${boqNum}`);
  }
  
  getProjectSummary(projectId: string | number): Observable<IProjectSummaryResponse>
  {
    return this.http.get<IProjectSummaryResponse>(`${Constant.webapi}projects/${projectId}/GetProjectSummaryReport`);
  }

}
