import {ForgetPasswordComponent} from '../forget-password/forget-password.component';
import {Helpers} from '../../helpers/Helpers';
import {User} from '../../Models/User';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthenticationService, IJwtToken} from '../Services/authentication.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  date: Date = new Date();
  public isCollapsed = true;
  loginForm: FormGroup;
  isSubmitted = false;
  public user: User;
  erromsg = false;
  message: string;
  token: IJwtToken;
  showError: boolean = false;

  constructor(
    public help: Helpers,
    private router: Router,
    private formbuilder: FormBuilder,
    private authi: AuthenticationService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    if (this.help.isLoggedIn) {
      return this.navigateToDashboard();
    }

    const html = document.getElementsByTagName('html')[0];
    html.classList.add('auth-layout');
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('bg-default');

    this.router.events.subscribe((_) => {
      this.isCollapsed = true;
    });

    this.loginForm = this.formbuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  get formControls() { return this.loginForm.controls; }

  //#region Login Submit
  login() {

    this.isSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.user = this.loginForm.value;
    this.spinner.show();
    this.showError = false;
    this.authi.login(this.user).subscribe((res) => {
        this.spinner.hide();

      
          this.help.setitem('token', res.token);
          this.help.setitem('fname', res.firstName);
          this.help.setitem('clientId', res.adminClientId);
          this.help.setitem('id', res.userId);

         // this.token = jwt_decode(res.data.jwtToken);
         // this.help.setitem('role', this.token.Role);
          this.navigateToDashboard();
      
      }, (error: any) => {
        this.showError = true;
      }
    );
  }

  private navigateToDashboard() {
    return this.router.navigateByUrl('/admin/dashboard');
  }

  //#endregion

  //#region ngOnDestory
  ngOnDestroy() {
    const html = document.getElementsByTagName('html')[0];
    html.classList.remove('auth-layout');
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('bg-default');
  }

  //#endregion

  //#region ForgetPassword Model Pop Up
  forgetPassword() {
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = true;
    dialogconfig.autoFocus = true;
    dialogconfig.width = '100%';
    this.dialog.open(ForgetPasswordComponent, {
      width: '500px',
    });
  }
  //#endregion
}
