import {IRefPackage} from '../../Models/IRefPackage';
import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AdminService} from 'src/app/admin/admin-service/admin.service';
import {Helpers} from 'src/app/helpers/Helpers';
import {NgxSpinnerService} from 'ngx-spinner';
import {IProjectViewModel} from '../../Models/projectViewModel';
import {MatSnackBar} from '@angular/material/snack-bar';
import {EPackageClassification, EPackageOptionStatus} from '../../admin/admin-service/classificationEnum';

export interface IAddPackageComponentData {
  data: IProjectViewModel & { isOptionPackage?: boolean, newPkgIndex?: number };
}

@Component({
  selector: 'app-addpackages',
  templateUrl: './add-packages.component.html',
  styleUrls: ['./add-packages.component.scss']
})
export class AddPackagesComponent implements OnInit {

  clicked = false;
  isSubmitted = false;
  packageForm: FormGroup;
  projectName: string;
  projectNum: any;
  packageDetails: IRefPackage;

  constructor(
    @Inject(MAT_DIALOG_DATA) public externalData: IAddPackageComponentData & {newPkgIndex?: number},
    private dialogref: MatDialogRef<AddPackagesComponent>,
    private dialog: MatDialog,
    private authi: AdminService,
    private help: Helpers,
    private formbuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private snackBar: MatSnackBar
  ) {
  }

  get formControls() {
    return this.packageForm.controls;
  }

  ngOnInit() {
    this.projectName = this.externalData.data.projectName;
    this.projectNum = this.externalData.data.projectNum;
    const form = this.formbuilder.group({
      projectname: ['', Validators.required],
      packageCd: ['', Validators.required],
      packageName: ['', Validators.required],
      packageDesc: ['', Validators.required]
    });

    if (this.externalData.data.newPkgIndex) {
      form.controls.packageCd.setValue(`OPTION ${this.externalData.data.newPkgIndex}`);
      form.controls.packageCd.disable();
    }

    this.packageForm = form;
  }

  //#region Submit After Add Pacakge

  submit() {
    this.clicked = true;
    this.isSubmitted = true;
    if (this.packageForm.invalid) {
      this.clicked = false;
      return;
    }
    this.packageDetails = this.packageForm.value;
    this.packageDetails.packageCd = this.packageForm.controls.packageCd.value;
    this.packageDetails.projectNum = this.projectNum;
    this.packageDetails.createdBy = this.help.getitem('fname');
    this.packageDetails.statusInd = 1;
    this.packageDetails
      .packageClassificationInd = this.externalData.data.isOptionPackage ?
      EPackageClassification.Option : EPackageClassification.Base;
    this.packageDetails.packageOptionStatusInd = this.externalData.data.isOptionPackage ?
      EPackageOptionStatus.Review : EPackageOptionStatus.NotApplicable;
    this.spinner.show();
    this.authi.addPackage(this.packageDetails).toPromise().then((res: any) => {
      if (res.succeeded) {
        this.packageDetails.packageNum = res.data.packageNum;
        this.dialogref.close({event: 'submit', data: this.packageDetails});
        this.snackBar.open(
          'Package has been successfully added',
          'OK',
          {
            duration: 5000,
            panelClass: 'modal-snackbar--success',
            verticalPosition: 'top'
          });
      } else {
        throw new Error('Failed to add package. Error:' + res.message);
      }
    }).catch((err: any) => {
      console.error(err);
      this.snackBar.open(
        'Failed to add package.',
        'OK',
        {
          duration: 5000,
          panelClass: 'modal-snackbar--error',
          verticalPosition: 'top'
        });
    }).finally(() => {
      this.clicked = false;
      this.spinner.hide();
    });
  }

  //#endregion

  //#region  Close Dialog Box

  closeDialoge() {
    this.dialogref.close({event: 'cancel'});
  }

  //#endregion
}
