import * as _ from 'lodash';
import { IBoqClassification } from 'src/app/admin/admin-service/IProjectSummaryReport';
import {
  EBoqClassification,
  EInfrastructureClassification,
  EPackageOptionStatus,
  getInfrastructureClassification
} from '../../admin/admin-service/classificationEnum';
import {IInfrastructure} from '../../Models/IInfrastructure';

export interface IProjectSnapshotDetail {
  project_name: string;
  projectNum: number;
}

export interface IInfrastructureDetail extends IProjectSnapshotDetail,
  Pick<IInfrastructure, 'infrastructureClassificationInd' | 'infrastructurePreferredInd'> {
  base: number;
  costChangePercentage: string;
  costChangeType: CostChangeType;
  baseBoqLink?: number; //boqNum
  boqClassificationInd: EBoqClassification;
  baseOption: number;
  baseOptionBoqLink?: number;
  baseVerified: number;
  baseVerifiedBoqLink?: number;
  boqNum?: number;
  budget_amt: number;
  document_guid?: string;
  infrastructure_name: string;
  infrastructure_desc: string;
  infrastructure_num: number;
  option: number;
  optionBoqLink?: number;
  package_name: string;
  package_desc: string;
  package_num: number;
  praxisValue: number;
  praxisValueBoqLink?: number;
  hasAnyZeroRate?: boolean;
  package_option_status_ind?: number;
}

export type BoqDetailDropDown = IProjectSnapshotDetail | IInfrastructureDetail;

export class InfrastructureDetail implements IInfrastructureDetail {
  public data: IInfrastructureDetail;
  
  public get packageOptionStatus(): EPackageOptionStatus {
    switch (this.data.package_option_status_ind) {
      case 0:
        return EPackageOptionStatus.Review;
      case 1:
        return EPackageOptionStatus.Accepted;
      case 2:
        return EPackageOptionStatus.Declined;
      case 3:
      case null:
      case undefined:
        return EPackageOptionStatus.NotApplicable;
      default:
        throw new Error(`Invalid packageOptionStatusInd: ${this.data.package_option_status_ind}`);
    }
  }

  public get possibleBoqClassifications(): IBoqClassification[] {
    const result = [
      {boqClassificationId: '', boqClassificationName: 'Choose'},
    ];
    switch (this.infrastructureClassification) {
      case EInfrastructureClassification.Base:
        result.push(
          {boqClassificationId: '1', boqClassificationName: 'Base'},
          {boqClassificationId: '2', boqClassificationName: 'Base Verified'},
        );
        break;
      case EInfrastructureClassification.Option:
        result.push({boqClassificationId: '4', boqClassificationName: 'Option'});
        break;
      case EInfrastructureClassification.BaseOption:
        result.push({boqClassificationId: '5', boqClassificationName: 'Base Option'});
        break;
      default:
        throw new Error(`[get possibleBoqClassifications] Invalid infrastructureClassification: ${this.infrastructureClassification}`);
    }
    return result;
  }
  
  constructor(data: IInfrastructureDetail) {
    this.data = data;
  }

  costChangePercentage: string;
  costChangeType: CostChangeType;  

  public get infrastructureClassificationInd(): EInfrastructureClassification {
    return this.data.infrastructureClassificationInd;
  }

  public get boqClassificationInd(): EBoqClassification {
    return this.data.boqClassificationInd;
  }

  public get shouldDisplayOnDashboard(): boolean {
    return this.infrastructureClassification === EInfrastructureClassification.Base && (
      this.withoutAnyBoqLine ||
      this.isBaseBoqLine
    );
  }

  private get withoutAnyBoqLine(): boolean {
    return _.isNil(this.boqClassificationInd);
  }

  private get isBaseBoqLine(): boolean {
    return this.boqClassificationInd === EBoqClassification.Base;
  }

  public get base(): number {
    return this.data.base;
  }

  public get baseOption(): number {
    return this.data.baseOption;
  }

  public get baseVerified(): number {
    return this.data.baseVerified;
  }

  // tslint:disable-next-line:variable-name
  public get budget_amt(): number {
    return this.data.budget_amt;
  }

  public get infrastructureClassification(): EInfrastructureClassification {
    return getInfrastructureClassification(this.data.infrastructureClassificationInd);
  }

  public get infrastructurePreferredInd(): number {
    return this.data.infrastructurePreferredInd;
  }

  public set infrastructurePreferredInd(value) {
    if (value === 0 || value === 1) {
      this.data.infrastructurePreferredInd = value;
      return;
    }
    throw new Error(`[set infrastructurePreferredInd] Invalid newVal for infrastructurePreferredInd: ${value}`);
  }

  // tslint:disable-next-line:variable-name
  public get infrastructure_name(): string {
    return this.data.infrastructure_name;
  }

  public get infrastructure_desc(): string {
    return this.data.infrastructure_desc;
  }

  public get package_desc(): string {
    return this.data.package_desc;
  }


  // tslint:disable-next-line:variable-name
  public get infrastructure_num(): number {
    return this.data.infrastructure_num;
  }

  public get option(): number {
    return this.data.option;
  }

  // tslint:disable-next-line:variable-name
  public get package_name(): string {
    return this.data.package_name;
  }

  // tslint:disable-next-line:variable-name
  public get package_num(): number {
    return this.data.package_num;
  }

  public get praxisValue(): number {
    return this.data.praxisValue;
  }

  public get projectNum(): number {
    return this.data.projectNum;
  }

  // tslint:disable-next-line:variable-name
  public get project_name(): string {
    return this.data.project_name;
  }

  public get hasAnyZeroRate(): boolean {
    return this.data.hasAnyZeroRate;
  }

  public get infrastructureCost(): number {
    switch (this.infrastructureClassification) {
      case EInfrastructureClassification.Base:
        return this.base;
      case EInfrastructureClassification.Option:
        return this.option;
      case EInfrastructureClassification.BaseOption:
        return this.baseOption;
    }
    throw new Error(`[get infrastructureCost()] Invalid infrastructureClassificationInd = ${this.infrastructureClassification}`);
  }

  public get boqLink(): number | undefined {
    switch (this.infrastructureClassification) {
      case EInfrastructureClassification.Base:
        return this.data.baseBoqLink;
      case EInfrastructureClassification.Option:
        return this.data.optionBoqLink;
      case EInfrastructureClassification.BaseOption:
        return this.data.baseOptionBoqLink;
      default:
        throw new Error(`[get boqLink()] Invalid infrastructureClassification: ${this.infrastructureClassification}`);
    }
  }

  public get boqNum(): number | undefined {
    return this.data.boqNum;
  }

  public negatePreferredInd(): void {
    if (this.infrastructurePreferredInd === 0) {
      this.infrastructurePreferredInd = 1;
      return;
    }
    if (this.infrastructurePreferredInd === 1) {
      this.infrastructurePreferredInd = 0;
      return;
    }
    throw new Error(`[negatePreferredInd] Invalid existing value for infrastructurePreferredInd: ${this.infrastructurePreferredInd}`);
  }
}

export enum CostChangeType {
  None = 0,
  Increase = 1,
  Decrease = 2
}