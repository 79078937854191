import {Constant} from '../../shared/Constant';
import {User} from '../../Models/User';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IPraxisAPIResult} from '../../admin/admin-service/IPraxisAPIResult';
import { UserRole } from 'src/app/Models/user-role';

export interface IAuthenticationResult {
  jwtToken: string;
  firstName: string;
  id: string; 
  isAdminInd: boolean;
  role?: UserRole[];
  clientId: number | string;
}

export interface IJwtToken {
  aud: string;
  exp: number;
  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name': string;
  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier': number;
  iss: string;
  jti: string;
  nbf: number;
  Role: string;
  UserNum: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(private http: HttpClient) {
  }

  login(user: User): Observable<any> {
    return this.http.post<any>(Constant.authapi + '/login', user);
  }
  forgetPassword<T>(email: string): Observable<T> {
    const forgetpassword = {
      email
    };
    return this.http.post<T>(Constant.authapi + '/ForgotPassword', forgetpassword).pipe();
  }

  verifiedToken<T>(token: string, email: string, password: string): Observable<T> {
    const data = {
      token,
      email,
      password
    };
    console.log(data);
    return this.http.post<T>(Constant.authapi + '/verify', data);
  }

  setPassword<T>(token: string, email: string ,password: string, confirmPassword): Observable<T> {
    const data = {
      token,
      email,
      password,
      confirmPassword
    };
    return this.http.post<T>(Constant.authapi + '/ResetPassword', data);
  }
}
