import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AdminService} from '../../../admin/admin-service/admin.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UserView} from '../../../Models/UserView';
import {AlertBoxComponent} from '../../alertbox/alert-box.component';

@Component({
  selector: 'app-edituser',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private authi: AdminService,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: {
      user?: UserView,
      clients: Array<{
        clientId: number;
        name: string
      }>
    },
    private dialogRef: MatDialogRef<EditUserComponent>,
  ) { }

  clicked = false;
  formTitle: string;
  userForm: FormGroup;
  isSubmitted = false;
  show = false;
  dataUser: UserView;
  submitError?: string = null;

  ngOnInit(): void {
    if (this.data.user) {
      this.formTitle = 'Edit User ';
      this.dataUser = {...this.data.user};
    } else {
      this.show = false;
      this.formTitle = 'Add User';
      this.dataUser = {
        id: -1,
        email: '',
        firstName: '',        
        lastName: '',
        status: 0,
        client: null,
        role: 0,
        isAdmin: false
      };
    }
    this.userForm = this.formBuilder.group({
      firstName: [this.data.user ? this.data.user.firstName : '', Validators.required],
      lastName: [this.data.user ? this.data.user.lastName : '', Validators.required],
      email: [this.data.user ? this.data.user.email : '', [Validators.required, Validators.email]],
    });
  }

  get formControls() { return this.userForm.controls; }

  closeDialog() {
    this.dialogRef.close({ event: 'cancel' });
  }

  submit() {
    this.clicked = true;
    this.isSubmitted = true;
    this.submitError = null;
    console.log(this.userForm);
    if (this.userForm.invalid) {
      this.clicked = false;
      return;
    }
    this.dataUser.status = Number(this.userForm.value.status);
    this.spinner.show();
    if (this.data.user) {
      this.authi.updateUser(this.data.user.id, this.userForm.value).subscribe(() => {
        this.spinner.hide();
        this.clicked = false;
        this.dialogRef.close({
          event: 'submit',
          data: this.dataUser,
        });
        this.dialog.open(AlertBoxComponent, {
          disableClose: true,
          width: '500px',
          data: {
            res: {
              message: 'User has been updated'
            }
          }
        });
      }, (error => {
        this.spinner.hide();
        if (error === 'Conflict') {
          this.submitError = 'There is an account with this email.';
        }
        console.log(error);
      }));
    } else {
      this.authi.createUser<UserView>(this.userForm.value).subscribe((res) => {
        this.spinner.hide();
        this.clicked = false;
        this.dialogRef.close({
          event: 'submit',
          data: res,
        });
        this.dialog.open(AlertBoxComponent, {
          disableClose: true,
          width: '500px',
          data: {
            res: {
              message: 'User has been created'
            }
          }
        });
      }, (error => {
        this.spinner.hide();
        if (error === 'Conflict') {
          this.submitError = 'There is an account with this email.';
        }
        console.log(error);
      }));
    }
    this.clicked = false;
  }

  compareById(itemOne: {
    clientId: number;
    name: string
  },          itemTwo: {
    clientId: number;
    name: string
  }) {
    return itemOne && itemTwo && itemOne.clientId === itemTwo.clientId;
  }
}
