import { Pdfupload } from '../../Models/pdfUpload';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Helpers } from 'src/app/helpers/Helpers';
import { AdminService } from 'src/app/admin/admin-service/admin.service';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthGaurdGuard } from 'src/app/helpers/Auth-gurad/auth-gaurd.guard';

const ALLOWED_FILE_TYPES = ['pdf', 'jpeg', 'png', 'doc', 'docx', 'jpg'];

@Component({
  selector: 'app-pdf-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  pdfupload: FormGroup;
  isSubmitted = false;
  filedata: File;
  pdfData: Pdfupload;

  constructor(
    private dialogref: MatDialogRef<FileUploadComponent>,
    private formbuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public boqlinkdata: any,
    private help: Helpers,
    private authi: AdminService,
    private authGuard: AuthGaurdGuard,
    private loc: Location,
    private spinner: NgxSpinnerService,
  ) {
  }

  ngOnInit() {
    this.pdfData = {};
    this.pdfupload = this.formbuilder.group({
      documentpath: ['', Validators.required]
    });
  }

  get formControls() {
    return this.pdfupload.controls;
  }

  //#region PDF File

  FileUpload(event: any) {
    const size = event.target.files[0].size;
    const name = event.target.files[0].name;
    const lastDot = name.lastIndexOf('.');
    const ext = name.substring(lastDot + 1);
    if (size > 25000000) {
      this.pdfupload = this.formbuilder.group({
        documentpath: ['', Validators.required]
      });
      alert('Please Upload File less than 25 MB');
    }
    if (ALLOWED_FILE_TYPES.includes(ext)) {
      this.filedata = (event.target.files[0] as File);
    } else {
      this.pdfupload = this.formbuilder.group({
        documentpath: ['', Validators.required]
      });
      alert(`Please Upload one of these file types: ${ALLOWED_FILE_TYPES}`);
    }
  }

  //#endregion

  //#region Submit

  submit() {
    this.isSubmitted = true;
    if (this.pdfupload.invalid) {
      return;
    }
    this.spinner.show();
    this.pdfData.boq_num = this.boqlinkdata.boqNum;
    this.pdfData.client_num = this.boqlinkdata.clientNum;
    this.pdfData.document_num = 0;
    this.pdfData.created_by = this.help.getitem('fname');
    this.pdfData.status_ind = 1;
    const uploadData = new FormData();
    // tslint:disable-next-line:forin
    for (const key in this.pdfData) {
      uploadData.append(key, this.pdfData[key]);
    }
    uploadData.append('upload_file', this.filedata);

    if (this.boqlinkdata.pdfUpload) {
      this.authi.uploadPDFile(this.authGuard.projectId, uploadData).subscribe((res: any) => {
        this.spinner.hide();
        alert('Upload Successfully');
        this.dialogref.close({ event: 'submit', fileName: res?.data?.document_guid });
      }, (error: any) => {
        this.spinner.hide();
      });
    }
    else {
      this.authi.uploadFile(this.authGuard.projectId, uploadData).subscribe((res: any) => {
        this.spinner.hide();
        alert('Upload Successfully');
        this.dialogref.close({ event: 'submit', fileName: res?.data?.document_guid });
      }, (error: any) => {
        this.spinner.hide();
      });
    }
  }

  //#endregion

  //#region Close Modal Pop Up

  closedialoge() {
    this.dialogref.close({ event: 'cancel' });
  }

  //#endregion
}
