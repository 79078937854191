import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';
import {Helpers} from '../Helpers';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private totalRequests = 0;

  constructor(private help: Helpers, private spinner: NgxSpinnerService, private toastr: ToastrService) {
    
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.totalRequests++;

    return next.handle(req).pipe(finalize(() => {
  
      if(this.totalRequests !== 0 )
      this.totalRequests--;
      if (this.totalRequests === 0) {this.spinner.hide();
      }
  }), catchError((error: any) => {
                    let errorMsg = '';
                    if (error.error instanceof ErrorEvent) { //This is client side error
                    } else {     
                      this.spinner.hide();
                      this.totalRequests=0;
                        if (error.status == 401 || error.status == 403) {
                          this.toastr.info("Your current session has been expired.", 'Authorization Fail');
                           this.help.logout();
                        } else if (error.status == 400)                        
                        {
                          if(error.error === "Not allowed")
                          this.toastr.error("You don't have suffiecient privileges to perform this operation. Please contact your admin to give you more rights")
                          else
                            this.toastr.error(error?.error?.message ?? "Something went wrong. Please check the details and try again", "Invalid Request")
                        } else if (error.status == 500) {
                            this.toastr.error((error?.error?.message) ? error?.error?.message : "Something went wrong. Our support team is looking into it", "Failed Request")
                        }else if (error.status == 404) {         
                          this.help.notFound();                   
                        }
                    }
                    return throwError(errorMsg);         
    }));
  }
}
