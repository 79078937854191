import { Component, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { none } from 'fp-ts/lib/Option';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
 
function readBase64(file): Promise<any> {
    var reader  = new FileReader();
    var future = new Promise((resolve, reject) => {
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.addEventListener("error", function (event) {
        reject(event);
      }, false);

      reader.readAsDataURL(file);
    });
    return future;
  }

const URL = 'http://localhost:5000/api/ExcelFile/upload';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',  
  styleUrls: ['./file-uploader.component.scss']
  //encapsulation: ViewEncapsulation.None
})
export class FileUploaderComponent implements OnInit {

  public uploader: FileUploader = new FileUploader({});
  public hasBaseDropZoneOver:boolean = false;
  public mimeType: string;

  ngOnInit(){
    this.uploader = new FileUploader({
        url: URL,
        //disableMultipart: true,        
        authTokenHeader: "authorization",
        authToken: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VyTnVtIjoiMzEiLCJSb2xlIjoiMSIsIm5iZiI6MTY2ODE3MTU3OSwiZXhwIjoxNjY4Nzc2Mzc5LCJpYXQiOjE2NjgxNzE1Nzl9.SbqIWzt8M1-Q5XIlsojYO5POMEg0-I-8KrIaZR88RVk",
        isHTML5: true,
        allowedMimeType: ['application/octet-stream', 'application/x-zip-compressed']  
    });
    // this.uploader.onAfterAddingFile = f => {
    //     if (this.uploader.queue.length > 1) {
    //         this.uploader.removeFromQueue(this.uploader.queue[0]);
    //     }
    // };
    // this.uploader.onWhenAddingFileFailed = (item) => {
    //     console.log(item);
    // }    
  }

  fileObject: any;


  public fileOverBase(e:any):void {
    this.hasBaseDropZoneOver = e; 
  }

  public onFileSelected(event: EventEmitter<File[]>) {
    const file: File = event[0];


    readBase64(file)
      .then(function(data) {
    })

  }

  setMimeType(mimeType) {
    this.mimeType = mimeType;
  }
}