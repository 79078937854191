// example: when projectBoqCodeCd is R54P1.8 and projectBoqSectionCd is R54 the final output should be 1.8
export function getBoqOrder(boqCode: string, sectionCode: string) {
  let order = boqCode.trim().replace(sectionCode.trim(), '');
  // after projectBoqSectionCd there is a P or similar char that needs to be removed
  for (let i = 0; i < order.length; i++) {
    if (isNaN(Number(order[i]))) {
      continue;
    }
    order = order.substring(i);
    break;
  }
  return order.replace('(a)', '.1')
    .replace('(b)', '.2')
    .replace('(c)', '.3');
}

export function toMillion(n: number) {
  return `$${((n) / 1_000_000).toFixed(2)}M`;
}
