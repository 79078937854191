import {AdminService} from 'src/app/admin/admin-service/admin.service';
import {Helpers} from '../../helpers/Helpers';
import {Milestone} from '../../Models/Milestone';
import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NgxSpinnerService} from 'ngx-spinner';
import {AlertBoxComponent} from '../alertbox/alert-box.component';

@Component({
  selector: 'app-addmilestone',
  templateUrl: './add-milestone.component.html',
  styleUrls: ['./add-milestone.component.scss']
})
export class AddMilestoneComponent implements OnInit {

  clicked = false;
  isSubmitted = false;
  milestoneForm: FormGroup;
  projectName: string;
  projectNum: any;
  milestoneDetails: Milestone;

  constructor(
    @Inject(MAT_DIALOG_DATA) public milestonedata: any,
    private dialogref: MatDialogRef<AddMilestoneComponent>,
    private formbuilder: FormBuilder,
    private help: Helpers,
    private dialog: MatDialog,
    private authi: AdminService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.milestoneDetails = {};
    this.projectName = this.milestonedata.data.projectName;
    this.projectNum = this.milestonedata.data.projectNum;
    this.milestoneForm = this.formbuilder.group({
      projectname: ['', Validators.required],
      milestoneCd: ['', Validators.required],
      milestoneName: ['', Validators.required],
      milestoneDesc: ['', Validators.required]
    });
  }

  get formControls() { return this.milestoneForm.controls; }

  //#region Submit After Add Milestone

  submit() {
    this.clicked = true;
    this.isSubmitted = true;
    if (this.milestoneForm.invalid) {
      this.clicked = false;
      return;
    }
    this.milestoneDetails = this.milestoneForm.value;
    this.milestoneDetails.projectNum = this.projectNum;
    this.milestoneDetails.statusInd = 1;
    this.milestoneDetails.createdBy = this.help.getitem('fname');
    this.spinner.show();
    this.authi.addMilestone(this.milestoneDetails).subscribe((res: any) => {
      if (res.succeeded === true) {
        this.milestoneDetails.milestoneNum = res.data.milestoneNum;
        this.dialogref.close({event: 'submit', data: this.milestoneDetails});
        this.spinner.hide();
        this.dialog.open(AlertBoxComponent, {
          disableClose: true,
          data: {res},
          width: '500px',
        });
      }
    }, (err: any) => {
      console.log(err);
    });
  }

  //#endregion

  //#region Close Dialog Box

  closeDialoge() {
    this.dialogref.close({ event: 'cancel' });
  }

  //#endregion
}
