import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-alertbox',
  templateUrl: './alert-box.component.html',
  styleUrls: ['./alert-box.component.scss']
})
export class AlertBoxComponent implements OnInit {

  alerdata: any;
  isError: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private dialogref: MatDialogRef<AlertBoxComponent>,

  ) {
  }

  ngOnInit() {
    this.alerdata = this.data.res.message;
    this.isError = !!this.data.res.isError;
  }

  //#region Close Alert Dialog Box

  closeDialoge() {
    this.dialogref.close({ event: 'cancel' });
    this.dialog.closeAll();
  }
  //#endregion
}
