import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {AuthenticationService} from '../Services/authentication.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  forgetpassowrdFrom: FormGroup;
  isSubmitted = false;

  constructor(
    private dialogref: MatDialogRef<ForgetPasswordComponent>,
    private frombuilder: FormBuilder,
    private authi: AuthenticationService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {

    this.forgetpassowrdFrom = this.frombuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });

  }

  get formControls() { return this.forgetpassowrdFrom.controls; }

  //#region submit

  submit() {
    this.isSubmitted = true;
    if (this.forgetpassowrdFrom.invalid) {
      return;
    }
    this.spinner.show();
    this.authi.forgetPassword(this.forgetpassowrdFrom.value.email).subscribe(() => {
      this.spinner.hide();
      alert('We have sent an email to your registered email address , Please check!');
      this.dialogref.close({event: 'submit'});
    }, (error: any) => {
      console.log(error);
    });
  }

  //#endregion

  //#region close modal pop up

  closedialoge() {
    this.dialogref.close({ event: 'cancel' });
  }

  //#endregion
}
