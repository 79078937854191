import * as _ from 'lodash';
import { IInfrastructure } from '../../Models/IInfrastructure';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AdminService } from 'src/app/admin/admin-service/admin.service';
import { Helpers } from 'src/app/helpers/Helpers';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IPackagesInfraModel } from '../../Models/IPackagesInfraModel';
import { EInfrastructureClassification } from '../../admin/admin-service/classificationEnum';

export interface IAddInfrastructureParams {
  id: number; //projectNum
  isOptionInfrastructure: boolean;
  defaultPackageNum?: number;
}

@Component({
  selector: 'app-add-infrastructure',
  templateUrl: './add-infrastructure.component.html',
  styleUrls: ['./add-infrastructure.component.scss']
})
export class AddInfrastructureComponent implements OnInit {

  infrastructures: IPackagesInfraModel[] = [];
  clicked = false;
  isSubmitted = false;
  infraForm: FormGroup;
  infrastructureDetail: IInfrastructure;

  constructor(
    @Inject(MAT_DIALOG_DATA) public externalData: IAddInfrastructureParams,
    private dialogRef: MatDialogRef<AddInfrastructureComponent>,
    private dialog: MatDialog,
    private adminService: AdminService,
    private help: Helpers,
    private spinner: NgxSpinnerService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder) {
  }

  get formControls() {
    return this.infraForm.controls;
  }

  get packagesToDisplay(): Pick<IPackagesInfraModel, 'packageName' | 'packageNum'>[] {
    const firstOption = { packageNum: null, packageName: 'Choose' };
    if (!this.externalData.defaultPackageNum || _.isEmpty(this.infrastructures)) {
      return [firstOption, ...this.infrastructures];
    }

    const refPackage = this.infrastructures.find(value => value.packageNum === this.externalData.defaultPackageNum);
    if (!refPackage) {
      throw new Error(`Cannot find package with ID = ${this.externalData.defaultPackageNum}`);
    }
    return [
      firstOption,
      refPackage
    ];
  }

  ngOnInit() {
    this.getInfrastructureData(this.externalData.id);
    this.infraForm = this.formBuilder.group({
      packageNum: ['', Validators.required],
      infrastructureCd: ['', Validators.required],
      infrastructureName: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9 ]*$')]],
      infrastructureDesc: ['', Validators.required],
      infrastructureTypeInd: ['', Validators.required],
      budgetAmt: ['', Validators.required]
    });
  }

  //#region Submit After Add Infrastructure

  submit() {
    this.clicked = true;
    this.isSubmitted = true;
    console.log(this.infraForm.errors);
    if (this.infraForm.invalid) {
      this.clicked = false;
      return;
    }
    this.infrastructureDetail = this.infraForm.value;
    this.infrastructureDetail.infrastructureCd = this.infraForm.controls.infrastructureCd.value;
    this.infrastructureDetail
      .infrastructureClassificationInd = this.externalData.isOptionInfrastructure
        ? EInfrastructureClassification.Option
        : EInfrastructureClassification.Base;
    this.infrastructureDetail.packageNum = Number(this.infraForm.value.packageNum);
    this.infrastructureDetail.infrastructureTypeInd = Number(this.infraForm.value.infrastructureTypeInd);
    this.infrastructureDetail.createdBy = this.help.getitem('fname');
    this.infrastructureDetail.createdDt = new Date().toISOString();
    this.spinner.show().then(() => {
      this.adminService.addInfrastructure(this.externalData.id, this.infrastructureDetail).toPromise().then((res: any) => {
        if (res.succeeded) {
          this.infrastructureDetail.infrastructureNum = res.data.infrastructureNum;
          this.dialogRef.close({ event: 'submit', data: this.infrastructureDetail });
          this.snackBar.open(
            'Infrastructure has been successfully added',
            'OK',
            {
              duration: 5000,
              panelClass: 'modal-snackbar--success',
              verticalPosition: 'top'
            });
        } else {
          throw new Error('Failed to add package. Error:' + res.message);
        }
      }).catch((err: any) => {
        console.log(err);
        this.snackBar.open(
          'Failed to add infrastructure.',
          'OK',
          {
            duration: 5000,
            panelClass: 'modal-snackbar--error',
            verticalPosition: 'top'
          });
      }).finally(() => {
        this.clicked = false;
        return this.spinner.hide();
      });
    });
  }

  //#endregion

  //#region Get Packages List for Dropdown

  getInfrastructureData(id) {
    this.adminService.GetPackagesInfra(id).subscribe((res) => {
      this.infrastructures = res.data;
      if (!this.externalData.isOptionInfrastructure) {
         this.infrastructures = this.infrastructures.filter(c=>!c.refInfrastructures.find(c=>c.infrastructureCd ==='Base Option'));
      }
      else {
        this.getNewInfrastructureName(res.data);
      }
    }, (err: any) => {
      console.log(err);
    });
  }

  //#endregion

  //#region Close Dialog Box

  closeDialoge() {
    this.dialogRef.close({ event: 'cancel' });
  }

  //#endregion
  private getNewInfrastructureName(infra: IPackagesInfraModel[]) {
    const refPackage = infra.find(value => value.packageNum === this.externalData.defaultPackageNum);
    if (refPackage && refPackage.refInfrastructures.length) {
      const infraCode = `VARIATION ${this.numberToLetter(refPackage.refInfrastructures.length)}`;
      this.infraForm.controls.infrastructureCd.setValue(infraCode);
      this.infraForm.controls.infrastructureCd.disable();
    }
  }

  numberToLetter(num) {
    return String.fromCharCode(num + 65);
  }
}
