import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {AdminService} from 'src/app/admin/admin-service/admin.service';
import {Helpers} from 'src/app/helpers/Helpers';
import {IInfrastructureBoq} from 'src/app/Models/IInfrastructureBoq';
import {AlertBoxComponent} from '../alertbox/alert-box.component';
import {
  IBoqClassification,
  IInfraProjectBoq,
  InfrastructureWithAllBoqs
} from '../../admin/admin-service/IProjectSummaryReport';
import {MatSnackBar} from "@angular/material/snack-bar";
import { ExcelFileService } from 'src/app/serivces/excel-file.service';

interface IBOQList extends Omit<IInfraProjectBoq,
  'classificationCost' | 'refMilestones'> {
  infrastructureId: number;
}

export interface IAddInfrastructureBoqComponentExternalData {
  infrastructureId: number;
  infrastructureDetail: InfrastructureWithAllBoqs;
}


interface IControlConfig {
  defaultValue: any;
  controlName: string;
  disabled?: boolean;
  required?: boolean;
}

@Component({
  selector: 'app-add-infrastructure-boq',
  templateUrl: './add-infrastructure-boq.component.html',
  styleUrls: ['./add-infrastructure-boq.component.scss']
})

export class AddInfrastructureBOQComponent implements OnInit {
  clicked = false;
  addBOQDetails?: IInfrastructureBoq;
  isSubmitted = false;
  boqName: string;
  fileNames:Array<string>;
  milestonesData = [];
  boqForm: FormGroup;
  currentState: string = "AddBoq";
  submitUploadClicked = false;
  showFileUpload = false;
  fileUploaded = false;
  quantitiesToBeImported = [];
  showImportQuantites = false;
  boqFile: string = undefined;
  public files: any[] = [];

 projectNum = 1553;
  constructor(
    @Inject(MAT_DIALOG_DATA) public externalData: IAddInfrastructureBoqComponentExternalData,
    private formBuilder: FormBuilder,
    private excelFileService: ExcelFileService,
    private adminService: AdminService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private help: Helpers,
    private dialog: MatDialog,
    private matDialogRef: MatDialogRef<AddInfrastructureBOQComponent>,
    private snackBar: MatSnackBar
  ) {
  }

  public possibleOptions: IBoqClassification[];

  public get boqList(): IBOQList {
    return {...this.externalData.infrastructureDetail, infrastructureId: this.externalData.infrastructureId};
  }

  get formControls() {
    return this.boqForm.controls;
  }

  ngOnInit() {
    const tbl = this.boqList.refBoqs.length + 1;
    this.boqName = 'CALC-' + tbl;
    this.getMilestones(this.boqList.projectNum);
    this.projectNum = this.boqList.projectNum;
    this.possibleOptions = this.externalData.infrastructureDetail.possibleBoqClassifications;
    // this.boqForm = this.formBuilder.group({
    //   // projectStageInd: [''],
    //   // ProjectName: [''],
    //   // InfraName: [''],
    //   // boqName: [''],
    //   // boqClassificationInd: ['', Validators.required],
    //   // milestoneNum: [''],
    //   // boqDesc: [''],
    //   // boqModelId: [''],
    // });

    const controls = [
      {defaultValue: this.boqList.projectStageInd, controlName: 'projectStageInd', disabled: true} as IControlConfig,
      {defaultValue: this.boqList.infrastructureName, controlName: 'InfraName', disabled: true} as IControlConfig,
      {defaultValue: this.boqName, controlName: 'boqName', disabled: true} as IControlConfig,
      {defaultValue: this.boqList.projectName, controlName: 'ProjectName', disabled: true} as IControlConfig,
      {defaultValue: '', controlName: 'boqClassificationInd', required: true} as IControlConfig,
      {defaultValue: '', controlName: 'milestoneNum'} as IControlConfig,
      {defaultValue: '', controlName: 'boqDesc'} as IControlConfig,
      {defaultValue: '', controlName: 'boqModelId'} as IControlConfig,
    ].map(info => {
      return {info, control: this.addControlToGroup(info)};
    });
    const temp = {};
    controls.forEach((v) => {
      temp[v.info.controlName] = v.control;
    });
    this.boqForm = new FormGroup(temp);
  }

  submitUpload()
  {
    this.submitUploadClicked = true;
    this.submit();
  }

  submit() {
    this.clicked = true;
    this.isSubmitted = true;
    if (this.boqForm.invalid) {
      this.clicked = false;
      return;
    }
    this.addBOQDetails = {
      projectStageInd: this.boqForm.controls.projectStageInd.value,
      boqName: this.boqForm.controls.boqName.value,
      boqDesc: this.boqForm.controls.boqDesc.value,
      boqModelId: this.boqForm.controls.boqModelId.value,
      boqCd: this.boqForm.controls.boqName.value,
      parentBoqNum: null,
      milestoneNum: Number(this.boqForm.controls.milestoneNum.value),
      boqClassificationInd: Number(this.boqForm.controls.boqClassificationInd.value),
      infrastructureNum: this.boqList.infrastructureId,
      clientNum: this.boqList.clientNum,
      createdBy: this.help.getitem('fname'),
      projectNum: this.boqList.projectNum,
      boq_header_num: null,
      boqNum: null
    };
    this.spinner.show().then(() => {
      this.adminService.addInfraBOQ(this.addBOQDetails).toPromise().then((res) => {
        if (res.succeeded) {
          if (this.addBOQDetails.milestoneNum) {
            this.addBOQDetails.milestone = this.milestonesData
              .filter(x => x.milestoneNum === this.addBOQDetails.milestoneNum)[0].milestoneName;
          }

          this.addBOQDetails.createdDt = new Date().toISOString();
          this.addBOQDetails.totalCost = res.data.totalCost;
          this.addBOQDetails.boqNum = res.data.boqNum;
          this.addBOQDetails.boq_header_num = res.data.boq_header_num;
          if(!this.submitUploadClicked)
          {
           this.matDialogRef.close({event: 'submit', data: this.addBOQDetails});
           this.snackBar.open(
            'Infrastructure BOQ has been successfully added',
            'OK',
            {
              duration: 5000,
              panelClass: 'modal-snackbar--success',
              verticalPosition: 'top'
            });
          }
           else
           {
            this.currentState = "Upload";
           }
      
        } else {
          throw new Error('Failed to add infrastructure BOQ. Error:' + res.message);
        }
      }).catch((err: any) => {
        this.snackBar.open(
          'Failed to add package.',
          'OK',
          {
            duration: 5000,
            panelClass: 'modal-snackbar--error',
            verticalPosition: 'top'
          });
      }).finally(() => {
        this.clicked = false;
        this.spinner.hide();
      })
    });
  }

  //#region Get Milestones List for Dropdown

  getMilestones(proid) {
    this.adminService.getMileStoneData(proid).subscribe((res: any) => {
      this.milestonesData = res.data;
    }, (err: any) => {
      console.log(err);
    });
  }

  //#endregion

  //#region  Submit After Add BOQ

  private addControlToGroup(info: IControlConfig): FormControl {
    const control = new FormControl();
    if (info.disabled) {
      control.disable();
    }
    control.setValue(info.defaultValue, {});
    if (info.required) {
      control.setValidators(Validators.required);
    }
    return control;
  }

  onFileChange(pFileList: File[]) {
    this.files = Object.keys(pFileList).map((key) => pFileList[key]);
    this.uploadFiles();
  }

  uploadFiles = () => {
    let files = this.files;
    if (files.length === 0) {
      return;
    }

    let filesToUpload: File[] = files;
    const formData = new FormData();
    this.fileNames = [];

    Array.from(filesToUpload).map((file, index) => {
      this.fileNames.push(file.name);
      return formData.append("file" + index, file, file.name);
    });
    this.spinner.show().then(()=>
    this.excelFileService
      .uploadTextFolder(formData, this.boqList.projectNum)
      .subscribe((res: any) => {  
        files.length = 0;
        this.fileUploaded = true;
        this.spinner.hide();
        this.currentState='GenerateBoq';
      }));
  };

  closeDialoge() {
    if(this.currentState !== 'AddBoq')
    this.matDialogRef.close({event: 'submit', data: this.addBOQDetails});
    else
    this.matDialogRef.close({event: 'cancel'});
  }

   generateBoq()
   {
    this.spinner.show().then(()=>
     this.excelFileService.macroGenerateBoqInfra(this.boqList.projectNum).subscribe(()=>{
     // this.boqFile = r;
        // this.adminService.importQuantitiesLatest(this.boqList.projectNum).subscribe(c=>{
        // this.currentState = "ImportQuantities";
        // this.quantitiesToBeImported = c;
        // this.spinner.hide();
        // });
     }));
   }

   importQuantities()
   {
   /* this.spinner.show().then(()=>
     this.adminService.importQuantities(this.boqList.projectNum,  this.addBOQDetails.boqNum).subscribe(()=>{
         this.currentState = "Complete";
         this.spinner.hide();
     }));*/
   }

   viewBoq()
   {
    
   }
  //#endregion
}
