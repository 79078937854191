import { environment } from '../../environments/environment';
export class Constant {
  // public static webapi = 'https://localhost:44344/api/';
  // public static filedata = 'https://localhost:44344/api/';


  // public static webapi = 'https://api.praxisengineering.com.au/api/';
  // public static filedata = 'https://api.praxisengineering.com.au/';
  public static webapi = environment.apiUrl + 'api/';
  public static authapi = environment.authUrl;
  public static filedata = environment.apiUrl;
}
